
const Sdata = [
  {
    btn: "APPLY NOW",
    title: "WE'RE HIRING",
    desc: "Full/Part Time Warehouse Positions",
    cover: "./images/bannerfirst.jpg",
  },
  {
    btn: "SEE HOW",
    title: "ESSENDANT FULFILLMENT SERVICES",
    desc: "Drive your growth with our expert supply chain solutions.",
    cover: "./images/bannerscnd.jpg",
  },
  {
    btn: "DICOVER MORE",
    title: "WE'RE POWERING MORE.",
    desc: "More possibilities to fuel your success",
    cover: "./images/bannerthird.jpg",
  },
]
export default Sdata
