import "./App.css";
import Header from "./Header/Header";
import React, { Fragment } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Homepage from "./Home/Homepage";
import Footer from "./footer/Footer";
import About from "./AboutUs/About";
import Customer from "./Customer/Customer";
import Suppliers from "./Supplier/Suppliers";
import Careers from "./Careers/Careers";
import Services from "./Services/Services";
import Location from "./AboutUs/dropmenu/Location";
import Leadership from "./AboutUs/dropmenu/Leadership";
import History from "./AboutUs/dropmenu/History";
// import Navbar from "./Header/Navbar";

function App() {
  return (
    <Router>
    {/* <Navbar/> */}
      <Header/>
      <Fragment>
        <Routes>
         <Route exact path="/"><Route exact path="/" element={<Homepage/>}/>
          </Route>
          <Route exact path="/aboutus"><Route exact path="/aboutus" element={<About/>}/>
          </Route>
          <Route exact path="/customer"><Route exact path="/customer" element={<Customer/>}/>
          </Route>
          <Route exact path="/suppliers"><Route exact path="/suppliers" element={<Suppliers/>}/>
          </Route>
          <Route exact path="/careers"><Route exact path="/careers" element={<Careers/>}/>
          </Route>
          <Route exact path="/services"><Route exact path="/services" element={<Services/>}/>
          </Route>
          <Route exact path="/locations"><Route exact path="/locations" element={<Location/>}/>
          </Route>
          <Route exact path="/leadership"><Route exact path="/leadership" element={<Leadership/>}/>
          </Route>
          <Route exact path="/history"><Route exact path="/history" element={<History/>}/>
          </Route>
          
        </Routes>
      </Fragment>
      <Footer/>
    </Router>
  );
}

export default App;
