import React from 'react'
import Home from './Home'

const Homepage = () => {
  return (
   <>
    <Home/>
   </>
  )
}

export default Homepage