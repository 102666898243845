import React from 'react'
import Applynow from '../Header/Applynow'
import Abouthome from './Abouthome'
import Findhome from './Findhome'
import Slider from './Slider'

const Home = () => {
  return (
   <>
   <Applynow/>
    <Slider/>
    <Abouthome/>
    <Findhome/>
   </>
  )
}

export default Home