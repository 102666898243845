import React from "react";
import { Link } from "react-router-dom";

import { useState } from "react";
import "./Header.css";
import Dropdown from "./Dropdown";
import { serviceDropdown, customerDropdown, aboutDropdown } from "./NavItems";

import Button from "./Button";
import { navItems } from "./NavItems";

const Navbar = () => {
  // const [menu, setMenu] = useState(false);
  const [dropdown, setDropdown] = useState(false);
  const [dropmenu, setDropmenu] = useState(false);

  return (
    <>
      {/* <header className="header">
        <div className="navlink">
          <ul
          >
            <li>
              <NavLink to="/">
                <div>
                <img src="essenlogo.jpg" alt="" />
                </div>
              </NavLink>
            </li>
            <li  onMouseEnter={() => setDropdown(true)} onMouseLeave={() => setDropdown(false)}>
              <NavLink to="/aboutus">ABOUT US</NavLink>
              {dropdown && <ul className={dropdown ? "services-submenu clicked" : "services-submenu"} onClick={() => setDropdown(!dropdown)}> 
                   <li> <NavLink to="/locations" onClick={() => setDropdown(false)}>Locations </NavLink> </li>
                   <li ><NavLink  to="/leadership" onClick={() => setDropdown(false)} >ESSENDANT LEADERSHIP </NavLink> </li>
             </ul> }
              
            </li>
            <li>
              <NavLink to="/customer">CUSTOMERS</NavLink>
            </li>
            <li>
              <NavLink to="/suppliers">SUPPLIERS</NavLink>
            </li>
            <li>
              <NavLink to="/careers">CAREERS</NavLink>
            </li>
            <li>
              <NavLink to="/services">FULLFILMENT SERVICES</NavLink>
            </li>
            <li>
              <button className="navbtn">BECOME A CUSTOMER</button>
            </li>
          </ul>
        </div>
      </header> */}

      <nav className="navbar">
        <Link to="/" className="logo">
          <div>
            <img src="essenlogo.jpg" alt="" />
          </div>
        </Link>
        {/* else if (item.title === "CUSTOMERS") else if (item.title === "SERVICES")  */}
        <ul className="nav-items">
          {navItems.map((item) => {
            if (item.title === "ABOUT US") {
              return (
                <li
                  key={item.id}
                  className={item.cName}
                  onMouseEnter={() => setDropdown(true)}
                  onMouseLeave={() => setDropdown(false)}
                >
                  <Link to={item.path}>{item.title}</Link>
                  {dropdown && <Dropdown />}
                  {/* {dropdown && (
                    <ul
                      className={
                        dropmenu
                          ? "services-submenu clicked"
                          : "services-submenu"
                      }
                      onClick={() => setDropmenu(!dropmenu)}
                    >
                      {aboutDropdown.map((item) => {
                        return (
                          <li key={item.id}>
                            <Link
                              to={item.path}
                              className={item.cName}
                              onClick={() => setDropdown(false)}
                            >
                              {item.title}
                            </Link>
                          </li>
                        );
                      })}
                    </ul>
                  )} */}
                </li>
              );
              
            }

            
           {/* if (item.title === "CUSTOMERS") {
              return (
                <li
                  key={item.id}
                  className={item.cName}
                  onMouseEnter={() => setDropdown(true)}
                  onMouseLeave={() => setDropdown(false)}
                >
                  <Link to={item.path}>{item.title}</Link>
                  {dropdown && (
                    <ul
                      className={
                        dropmenu
                          ? "services-submenu clicked"
                          : "services-submenu"
                      }
                      onClick={() => setDropmenu(!dropmenu)}
                    >
                      {customerDropdown.map((item) => {
                        return (
                          <li key={item.id}>
                            <Link
                              to={item.path}
                              className={item.cName}
                              onClick={() => setDropdown(false)}
                            >
                              {item.title}
                            </Link>
                          </li>
                        );
                      })}
                    </ul>
                  )}
                </li>
              );
            
            } */}






            {
              /* if (item.title === "CUSTOMERS") {
              return (
                <li
                  key={item.id}
                  className={item.cName}
                  onMouseEnter={() => setDropdown(true)}
                  onMouseLeave={() => setDropdown(false)}
                >
                  <Link to={item.path}>{item.title}</Link>
                  {dropdown && <Dropdown />}
                </li>
              );
            } */
            }
            
            return (
              <li key={item.id} className={item.cName}>
                <Link className="hline" to={item.path}>
                  {item.title}
                </Link>
              </li>
            );}
          )}
        </ul>
        <Button />
      </nav>
    </>
  );
};

export default Navbar;
